:root {
  --primary: #bc4123;
  --secondary: #ffffff;
  --text: #000000;
  --text-interact: #3b7196;
  --accent-text: #153242;
  --hill-color: #071219;
  --sky-color: #2c8aab;
  --light-sky-color: #66acc5;
}

h1.h1-Welcome{
  text-align:center;
  font-size:120px; 
  color: var(--accent-text); 
  font-family: verdana; 
  /* margin-top: 15% */
  /* transform: translateY(15%); */
}

h3.h1-Welcome{
  font-size: 120px !important;
}

.home-bg h1 {
  margin-bottom: 30px;
}

.achieve h1{
  margin-bottom: 2rem;
  text-align: center;
}

/* .home-bg img {
  border-radius: 100%;
  aspect-ratio: 1 / 1;
} */
.portrait {
  overflow: hidden;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  padding: 0 !important;
  border: 15px outset var(--secondary);
}
.portrait img {
  max-width: 100%;
}
.home-bg{
  color: var(--secondary);
  height: 100vh;
  padding: 50px;
  margin: 0 !important;
  align-items: center;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 20px 0px var(--hill-color);
}

.about{
  margin-top: 2%
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}



/* Set widths on the form inputs since otherwise they're 100% wide */
input,
select,
textarea {
  max-width: 2000px;
}

p, ul {font-size: 24px; font-family: 'Cabin', sans-serif;}



h1 {
  font-family: 'Cabin', sans-serif;
}

.jumbotron-welcome, .home{
  background: url('Components/images/MainPageBackground.svg') no-repeat fixed;
  background-size: cover;
}

row {
  text-align: center;
}

.home h3 {
  color: var(--secondary);
}
.slider {
  height: 28px;
  padding-left: 10px;
  overflow: hidden;
  display: inline-block;
}

.slider div {
  height: 30px;
  margin-bottom: 10px;
  padding: 2px 5px;
  text-align: left;
}

.slider-t1 {
  color: #94652a;
  animation: slide 7s linear infinite;
}

.slider-t2 {
  color: #1a2889;
}

.slider-t3 {
  color: #460898;
}

.slider-t4 {
  color: #126f4d;
}

.slider-t5 {
  color: #6e1414;
}

@keyframes slide {
  0% {margin-top: -200px;}
  10% {margin-top: -162px;}
  20% {margin-top: -162px;}
  30% {margin-top: -122px;}
  40% {margin-top: -122px;}
  50% {margin-top: -82px;}
  60% {margin-top: -82px;}
  70% {margin-top: -42px;}
  80% {margin-top: -42px;}
  90% {margin-top: -2px;}
  100% {margin-top: -2px;}
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .jumbotron {
      margin-top: 0px;
  }
  .body-content {
      padding: 0;
  }
}

.carousel-caption {
  bottom: 1rem;
  z-index: 10;
}

.carousel-item {
  height: 35rem;
  max-width: 100vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}



.btn-default{
  background-color: rgb(93, 143, 218) !important;
  color: rgb(255, 255, 255) !important;
}

.bg-primary {
  background-color:rgb(16, 41, 78) !important;
}

.card-footer{
  background: transparent;
  border-top: 0px;
}

.jumbotron, .home > .container {
  height: 100vh;
  background-size: cover;
  transform: translateY(25%);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


body {
  margin: 0;
  /* padding-bottom: 20px; */
  font-family: 'Cabin', sans-serif;
  background-color: var(--secondary) !important;
}
* {
  margin: 0;
}
p {
  margin: 20px 0;
}

h3 {
  font-family: 'Cabin', sans-serif;
}

li {
  font-family: 'Cabin', sans-serif;
}

button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: var(--text);
  background: var(--primary);
  cursor: pointer;
  font-size: 18px;
}

/* navbar */
#logo {
  fill: var(--secondary);
  padding: 0.3rem 0 0;
  margin-right: auto;
}
#logo:hover {
  background: none;
  fill: var(--accent-text);
}
header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background: var(--sky-color);

}
header nav {
  display: flex;
  justify-content: end;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}
header nav h1 {
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
}
header nav a{
  text-decoration: none;
  font-size: 1.25rem;
  padding: 0 1rem;
  color: var(--secondary);
  height: 4rem;
  transition: 0.3s;
  line-height: 4rem;
  font-family: 'Cabin', sans-serif;
}

header nav a:hover, header nav a:focus, header nav a:active {
  color:var(--text-interact);
  background: var(--accent-text);
}
/* header nav a.active {
  background: var(--primary);
} */

footer {
  margin-bottom: 100px;
  text-align: left !important;
}
/* page content */
main {
  margin: 0 auto;
  color: var(--text);
}
main > .container {
  margin-top: 150px;
  /* gap: 100px;
  display: flex;
  flex-direction: column; */
}
.about .row {
  margin-bottom: 100px;
}
.about .row:last-of-type {
  margin-bottom: 0;
}
.about-hello {
  align-items: center;
}
.abt-icon {
  width: 70%;
  display: block;
  margin: 0 auto 10%;
  border: solid 5px var(--light-sky-color);
  border-radius: 100%;
  padding: 13%;
}
.icon-card h3 {
  text-align: center;
  padding-bottom: 0.6em;
  border-bottom: 1px solid var(--text);
}
.about h1 {
  margin: 0 0 3rem;
}
.about .row {
  align-items: top;
  padding: 50px;
}
.about .row:nth-child(even) {
  background: var(--light-sky-color);
  color:  var(--secondary);
}
.about .row:nth-child(even) .abt-icon {
  border-color: var(--secondary);
}
.about .row:nth-child(even) h3 {
  border-color: var(--secondary);
}
.findme p {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.findme p a {
  flex: 1 1 25%;
}
.logos {
  padding: calc(var(--bs-gutter-x) * 0.5);
  border-radius: var(--bs-border-radius);
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.logos a {
  flex: 1 1 33%;
}
.card {
  color: var(--hill-color);
}
.achieve ul{
  font-size: 20px;
}
.achieve > .row:first-of-type {
  margin-bottom: 15px;
}
.achieve .row.mb-3 {
  margin-bottom: 100px !important;
  align-items: center;
}


.achieve img {
  width: 70%;
  margin: 0 auto;
  display: block;
}
.projects {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  column-gap: 5%;
  row-gap: 40px;
}
.projectsheader {
  flex: 1 1 100%;
}
.projects .card {
  flex: 0 1 47.5%;
}
main > .contact {
  gap: initial;
}
.btn-primary {
  background-color: var(--sky-color) !important;
  color: var(--secondary) !important;
}

.card-footer-txt {
  font-size: 20px !important;
}